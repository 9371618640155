import React, { useEffect, useState } from 'react'
import { device } from './device'
import styled from 'styled-components'
const ButtonTOtheTop = styled.div`
  background-color: var(--primary-color);
  border: none;
  border-radius: 50%;
  //overflow-x: hidden; //horizontal
  cursor: pointer;
  font-size: 25px;
  line-height: 60px;
  width: 60px;
  float: right;
  text-align: center;

  z-index: 1;
`
const ButtonHolderToTop = styled.div`
  position: fixed;
  bottom: 90px;
  right: 30px;

  @media ${device.tabletMaxL} {
    position: fixed;
    bottom: 90px;
    right: 30px;
    left: 30px;
  }
`

const ScrollTop = () => {
  const [isVisible, setIsVisible] = useState(false)

  const toggleVisibility = () => {
    if (window.pageYOffset > 600) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }

  // Set the top cordinate to 0
  // make scrolling smooth
  const ScrollToTopOnMount = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility)
  }, [])

  return (
    <ButtonHolderToTop>
      {isVisible && (
        <ButtonTOtheTop
          id="scrollToTopBtn"
          onClick={() => {
            ScrollToTopOnMount()
          }}
        >
          <span role="img" aria-label="Up">
            ☝️
          </span>
        </ButtonTOtheTop>
      )}
    </ButtonHolderToTop>
  )
}
export default ScrollTop
